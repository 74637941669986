import * as SwitchPrimitives from "@radix-ui/react-switch";
import type { VariantProps } from "class-variance-authority";
import { cva } from "class-variance-authority";
import * as React from "react";

import { cn } from "../../lib/utils";

const switchVariants = cva(
	"peer inline-flex shrink-0 cursor-pointer items-center rounded-full border-2 border-transparent transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 focus-visible:ring-offset-background disabled:cursor-not-allowed disabled:opacity-50 data-[state=checked]:bg-primary data-[state=unchecked]:bg-gray-300",
	{
		variants: {
			variant: {
				default: "h-6 w-11",
				small: "h-5 w-10",
			},
		},
		defaultVariants: {
			variant: "default",
		},
	},
);

const switchThumbVariants = cva(
	"pointer-events-none block size rounded-full bg-background shadow-lg ring-0 transition-transform ",
	{
		variants: {
			variant: {
				default:
					"size-5 data-[state=checked]:translate-x-5 data-[state=unchecked]:translate-x-0",
				small: "size-3.5 data-[state=checked]:translate-x-5 data-[state=unchecked]:translate-x-0.5",
			},
		},
		defaultVariants: {
			variant: "default",
		},
	},
);

interface SwitchProps extends React.ComponentPropsWithoutRef<typeof SwitchPrimitives.Root> {
	variant?: VariantProps<typeof switchVariants>["variant"];
}

const Switch = React.forwardRef<React.ElementRef<typeof SwitchPrimitives.Root>, SwitchProps>(
	({ className, ...props }, ref) => (
		<SwitchPrimitives.Root
			className={cn(switchVariants({ variant: props.variant, className }))}
			{...props}
			ref={ref}
		>
			<SwitchPrimitives.Thumb
				className={cn(switchThumbVariants({ variant: props.variant }))}
			/>
		</SwitchPrimitives.Root>
	),
);
Switch.displayName = SwitchPrimitives.Root.displayName;

export { Switch };
